@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global CSS */
body {
  margin: 0 !important;
  padding: 0 !important;
}

.payments .StripeElement {
  min-width: 550px;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 600px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.PaymentRequestButton {
  padding: 0;
}

label.stripe-card {
  /* font-weight: 300; */
  font-size: 1rem;
}

button.stripe-card {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 30px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  transition: all 150ms ease;
  margin-top: 10px;
}

.primary-dark {
  color: #394b59;
}

.bg-dark {
  background-color: #394b59;
}

.w-45 {
  width: 45%;
}

.h-95 {
  height: 95%;
}

.child-m2 > :nth-child(n) {
  margin-right: 10px;
  margin-bottom: 10px;
}

.mb-15px {
  margin-bottom: 15px;
}

.child-mb2 > :not(:last-child) {
  margin-bottom: 5px;
}

.child-mv2 > :not(:first-child):not(:last-child) {
  margin-top: 5px;
  margin-bottom: 5px;
}

.child-mr2 > :not(:first-child):not(:last-child) {
  margin-right: 10px;
}

.child-mh2 > :nth-child(2n):not(:last-child) {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.flex-s {
  display: flex;
  justify-content: flex-start;
}

.flex-s-b {
  display: flex;
  justify-content: start;
  align-items: baseline;
}

.f-wrap {
  flex-wrap: wrap;
}
.flex-1 {
  flex: 1;
}

.w-7 {
  width: 7%;
}

.w-15 {
  width: 15%;
}

.w-65 {
  width: 65%;
}

.hover-shadow-1:hover {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2),
    0 18px 46px 6px rgba(16, 22, 26, 0.2);
}

/* Component Specific style */

.tf-box {
  height: 400px;
  overflow-y: scroll;
}

.invisible {
  display: none !important;
}
.hidden {
  visibility: hidden;
}

.client-selector {
  position: absolute;
  margin-left: 12px;
  bottom: 87px;
  bottom: 13vh;
}

.client-selector > select {
  width: 150px;
}

/* Themes */
input[type='email']:invalid {
  box-shadow: 0 0 0 0 rgba(217, 130, 43, 0), 0 0 0 0 rgba(217, 130, 43, 0),
    inset 0 0 0 1px #d9822b, inset 0 0 0 1px rgba(16, 22, 26, 0.15),
    inset 0 1px 1px rgba(16, 22, 26, 0.2);
}

/* Data Sources and Integrations */

.tr-error-highlight {
  background: #ffccbc !important;
}

/* Login page*/

.login-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.login-content {
  flex: 1;
  display: flex;
}

.login-content-image {
  margin-top: 5vh;
  background-size: contain;
  width: 40%;
  height: auto;
  object-fit: contain;
}

.login-content-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-box {
  width: 70%;
  height: 90vh;
  display: flex;
  flex-flow: column nowrap;
  border: 1px var(--light-blue-color) solid;
}

.login-box-head {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  color: var(--light-blue-color);
}

.login-box-foot {
  flex-basis: 45%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  background-color: var(--light-blue-color);
  padding: 10px;
}

.max-text-width {
  max-width: 80%;
}

.errorText {
  color: red;
  font-size: 12px;
  max-width: 80%;
  margin: 5px 0;
}

/* AG GRID */
.ag-header-cell-label {
  justify-content: center;
}
