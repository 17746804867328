.login-wrapper{
	width: 450px;
}

form.login{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}