.status-icon-div{
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50%;
    margin-left: 12px;
    /* position: absolute; */
    /* top: 4px; */
    /* top: 35px; */
    /* right: 180px; */
}

.status-icon-div.active {
    background-color: #0F9960;    
}

.status-icon-div.inactive {
    background-color: #DB3737;   
}

.status-icon-div.draft {
    background-color: #738694;    
}


.status-icon-div > span {
    font-size: 16px;
    color: white;
    line-height: 30px;
}

ol.progtrckr {
    padding-bottom: 2rem;
    list-style-type: none;
  }
  ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 4.5rem;
    padding: 0 0.7rem;
    cursor: pointer;
  }
  @media (max-width: 650px) {
    .progtrckr li span {
      display: none;
    }
  }
  @media (max-width: 650px) {
    .progtrckr em {
      display: inline;
    }
  }
  
  ol.progtrckr li.progtrckr-todo {
    color: silver;
    border-bottom: 4px solid silver;
  }
  ol.progtrckr li.progtrckr-doing {
    color: black;
    border-bottom: 4px solid #33C3F0;
  }
  ol.progtrckr li.progtrckr-done {
    color: black;
    border-bottom: 4px solid #33C3F0;
  }
  ol.progtrckr li:after {
    content: "\00a0\00a0";
  }
  ol.progtrckr li:before {
    position: relative;
    bottom: -3.7rem;
    float: left;
    left: 50%;
  }
  ol.progtrckr li.progtrckr-todo:before {
    content: "\039F";
    color: silver;
    background-color: white;
    width: 2.2em;
    line-height: 2.4em;
  }
  ol.progtrckr li.progtrckr-todo:hover:before {
    color: #0FA0CE;
  }
  
  ol.progtrckr li.progtrckr-doing:before {
    content: "\2022";
    color: white;
    background-color: #33C3F0;
    width: 2.2em;
    line-height: 2.4em;
    border-radius: 1.2em;
  }
  ol.progtrckr li.progtrckr-doing:hover:before {
    color: #0FA0CE;
  }
  
  ol.progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: white;
    background-color: #33C3F0;
    width: 2.2em;
    line-height: 2.4em;
    border-radius: 1.2em;
  }
  ol.progtrckr li.progtrckr-done:hover:before {
    color: #0FA0CE;
  }